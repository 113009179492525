<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="免单详情"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
    </template>
    <div style="max-height: 450px; overflow-y: scroll">
      <a-form>
        <a-descriptions bordered size="small" :column="1">
          <a-descriptions-item label="停车记录ID">{{
            freeDetailVo.parkingRecordId
          }}</a-descriptions-item>
          <a-descriptions-item label="免单原因">{{
            freeDetailVo.freeReason
          }}</a-descriptions-item>
          <a-descriptions-item label="免单时间">{{
            freeDetailVo.freeTime
          }}</a-descriptions-item>
          <a-descriptions-item label="免单操作人">{{
            freeDetailVo.freedBy
          }}</a-descriptions-item>
          <a-descriptions-item label="备注">{{
            freeDetailVo.remark
          }}</a-descriptions-item>
        </a-descriptions>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import { ref, onMounted, toRefs } from "vue";
import useMessage from "@/hooks/useMessage";
import useFreeRecord from "../../../../hooks/Info/useFreeRecord";
import { feeDetail } from "../../../../api/info/payRecordApi";

export default {
  name: "Edit",
  props: ["modalRef", "id"],
  emits: ["close"],
  setup(props, { emit }) {
    const disabled = ref(false);
    const { freeDetailVo } = useFreeRecord();
    const { showErr } = useMessage();
    const visible = ref(true);
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    const getFeeDetail = async () => {
      try {
        let { data } = await feeDetail(props.id);
        freeDetailVo.parkingRecordId = data.parkingRecordId;
        freeDetailVo.freeReason = data.freeReason;
        freeDetailVo.freeTime = data.freeTime;
        freeDetailVo.freedBy = data.freedBy;
        freeDetailVo.remark = data.remark;
      } catch (e) {
        await showErr(e);
      }
    };
    onMounted(async () => {
      getFeeDetail();
    });
    return {
      ...toRefs(props),
      disabled,
      visible,
      afterClose,
      freeDetailVo,
      getFeeDetail,
    };
  },
};
</script>

<style scoped></style>
